//02-12-20202
@import '~@fontsource/oxygen/scss/mixins';
@include fontFace($weight: 300, $fontDir: '~@fontsource/oxygen/files');

@import '~@fontsource/open-sans/scss/mixins';
@include fontFace($weight: 400, $fontDir: '~@fontsource/open-sans/files');

@import '~@fontsource/roboto/scss/mixins';
@include fontFace($weight: 900, $fontDir: '~@fontsource/roboto/files');
@include fontFace($weight: 700, $fontDir: '~@fontsource/roboto/files');
@include fontFace($weight: 500, $fontDir: '~@fontsource/roboto/files');
@include fontFace($weight: 400, $fontDir: '~@fontsource/roboto/files');

@import '~@fontsource/inter/scss/mixins';
@include fontFace($weight: 800, $fontDir: '~@fontsource/inter/files');
@include fontFace($weight: 400, $fontDir: '~@fontsource/inter/files');

@import '@fontsource/manrope';

@import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css');
$fern-green: #658e47;
$kermit-green: #64b900;
$green-gradient: linear-gradient(
  to bottom,
  rgba(100, 185, 0, 0.13),
  rgba(101, 142, 71, 0.13)
);

body {
  font-family: 'Roboto';
}

.top {
  z-index: 1;
}

.fix-height {
  min-height: 600px;
}

.text-green {
  color: #349b37;
}

.text-red {
  color: #ba2525;
}

.text-xxxs {
  font-size: 0.5rem;
  line-height: 1rem;
}

.background-color-gray {
  background-color: #fafafa;
}

.flex-sidebar {
  flex: 1;
}
.flex-main {
  flex: 3;
}
.flex-2 {
  flex: 2;
}

.text-almond-orange {
  color: #ed8f03;
}

.border-almond-orange {
  border-color: #ed8f03;
}

.text-xxs {
  font-size: 0.625rem;
}

.green-gradient-bg {
  background-image: $green-gradient;
}

.gray-text {
  color: #646464;
}

.fern-green-text {
  color: $fern-green;
}

.gray-solid-border {
  border: solid 1px #cbcbcb;
}

.bg-gray {
  background-color: #646464;
}

.font-open-sans {
  font-family: 'Open Sans';
}

.font-oxygen {
  font-family: 'Oxygen';
}

.bg-green {
  background-color: rgba(86, 147, 41, 0.1);
}

.fern-green-border {
  border-color: $fern-green;
}

.bullet-points {
  text-indent: -12px;
  margin-left: 22px;
  color: #3a3a3a;
}

.bullet-points li::before {
  content: '\2022';
  font-weight: bolder;
  display: inline-block;
  width: 12px;
  list-style-position: outside;
  font-size: 2.25rem;
  position: relative;
  top: 0.5rem;
}

.bullet-green li::before {
  color: #54a579;
}

.bullet-red li::before {
  color: #de5d3c;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  /* Webkit */
  width: 0;
  height: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}
